import {ContentType} from "app/reducers/content-types/types";
import {AppLink} from "common/link/Link";
import {AppText} from "common/text/Text";
import {getClassNames} from "utils/get-class-names/get-class-names";
import styles from "./styles.module.scss";

type AppContentTypeLinkProps = {
  to: string;
  type: ContentType;
  className?: string
}

export const AppContentTypeLink = (props: AppContentTypeLinkProps) => {
  const {to, type, className} = props;

  if (!type.pluralName) {
    return null;
  }

  return (
    <AppLink to={to} className={getClassNames(styles.tag, className)}>
      <AppText className={styles.text}>
        {type.pluralName}
      </AppText>
    </AppLink>
  );
};