import {createAsyncThunk} from "@reduxjs/toolkit";
import {NewAndPopularResponse} from "app/reducers/new-and-popular/types";
import {searchNewAndPopularContent} from "rest/search/search-new-and-popular";

export const getNewAndPopularContents = createAsyncThunk("FETCH_NEW_AND_POPULAR", async(userId?: string) => {
  try {
    const data: NewAndPopularResponse = await searchNewAndPopularContent(userId);
    return data;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});