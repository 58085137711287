import {useEffect} from "react";
import {getNewAndPopularContents} from "app/actions/new-popular/new-popular";
import {newAndPopularContentActions} from "app/reducers/new-and-popular";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {useAppSelector} from "app/store/hooks/use-selector";

export const useHomePage = () => {
  const {newAndPopularContentState, profileState} = useAppSelector(state => state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNewAndPopularContents(profileState.profile?.id));

    return () => {
      dispatch(newAndPopularContentActions.clear());
    };
  }, []);

  return {
    popularContent: newAndPopularContentState.popularContents,
    newContent: newAndPopularContentState.newContents,
    loading: newAndPopularContentState.loading,
  };
};