import React from "react";
import {ResourceElement} from "app/reducers/search/types";
import {AppCard} from "common/card/Card";
import {AppSpacer} from "common/spacer/Spacer";
import {AppInfoText} from "common/text/InfoText";
import {AppText} from "common/text/Text";
import {AppTitle} from "common/title/Title";
import {getDownloadUrl} from "utils/get-download-url/get-download-url";
import {getFullYear} from "utils/date/get-full-year/get-full-year";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {HighlightProps} from "types/props";
import {EHighlightPart} from "types/enums";
import {AppLazyImage} from "common/image/Image";
import {renderHighlightedNode} from "utils/highlight/render-highlighted-node/render-highlighted-node";
import {parseHTML} from "utils/html-parser/html-parser";
import previewStyles from "../styles.module.scss";
import styles from "./styles.module.scss";

type ContentCardProps = HighlightProps & {
  element: ResourceElement;
  className?: string;
  showContentType?: boolean;
  showDescription?: boolean;
}

export const ContentCard = (props: ContentCardProps) => {
  const {element, className, highlight, showContentType = true, showDescription = true} = props;
  const {image, name = "", source, publishDate, contentType, description, price} = element;
  const classNames = getClassNames(styles.card, className);
  const date = `${getFullYear(publishDate) ?? ""}`;

  const renderHighlighted = (currentPart: EHighlightPart, node: React.ReactNode) => {
    return renderHighlightedNode({
      partsToHighlight: highlight?.highlightParts,
      currentPart,
      node,
      pattern: highlight?.pattern,
    });
  };

  return (
    <AppCard className={classNames}>
      <AppSpacer direction="horizontal" unit="40">
        <AppLazyImage previewClassName={previewStyles[`preview-${contentType?.materialType}`]}
                      className={styles.image} src={getDownloadUrl(image)} alt={name}/>
        <AppSpacer unit="12" className={styles["info-container"]}>
          <AppSpacer unit="10" direction="horizontal">
            <AppTitle level={2} className={styles.title}>
              {renderHighlighted(EHighlightPart.NAME, name)}{name && date ? "," : ""}
            </AppTitle>
            <AppText className={styles.date}>
              {renderHighlighted(EHighlightPart.PUBLISH_DATE, date)}{date ? "г." : ""}
            </AppText>
          </AppSpacer>
          {
            showContentType && (
              <AppText className={styles["content-type"]}>
                {contentType?.name ?? ""}
              </AppText>
            )
          }
          {
            showDescription && description && (
              <AppText className={styles.description}>
                {renderHighlighted(EHighlightPart.DESCRIPTION, parseHTML(description))}
              </AppText>
            )
          }
          {
            source?.name && (
              <AppSpacer unit="8" direction="horizontal">
                <AppInfoText className={styles.source}>
                  Источник:
                </AppInfoText>
                <AppInfoText className={styles.source}>
                  {renderHighlighted(EHighlightPart.SOURCE_NAME, source?.name)}
                </AppInfoText>
              </AppSpacer>
            )
          }
        </AppSpacer>
        {
          price && (
            <AppSpacer alignItems="flex-end"
                       justifyContent="flex-start"
                       className={styles.price}>
              <AppText className={styles["price-text"]}>
                {price} ₽
              </AppText>
            </AppSpacer>
          )
        }
      </AppSpacer>
    </AppCard>
  );
};