import {AppSpacer} from "common/spacer/Spacer";
import {AppEmpty} from "common/empty/Empty";
import {ResourceElement} from "app/reducers/search/types";
import {ContentCardList} from "app/components/search/content-list/ContentList";
import styles from "./styles.module.scss";

type HomeContentProps = {
  newContent: ResourceElement[];
  popularContent: ResourceElement[];
}

export const HomeContent = (props: HomeContentProps) => {
  return (
    <AppSpacer unit="50" className={styles.container}>
      {
        !(props.newContent.length || props.popularContent.length) && (
          <AppEmpty/>
        )
      }
      {
        !!props.newContent.length && (
          <ContentCardList title="Новинки"
                           elements={props.newContent}
                           showDescription={false}/>
        )
      }
      {
        !!props.popularContent.length && (
          <ContentCardList title="Популярное"
                           elements={props.popularContent}
                           showDescription={false}/>
        )
      }
    </AppSpacer>
  );
};