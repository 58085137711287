import {useEffect, useState} from "react";
import {ResourceElement} from "app/reducers/search/types";
import {RESOURCE_PAGE_PATH} from "app/routing/resource/constants";
import {fetchResourceById} from "rest/resource/get-resource-by-id";
import {getSearchPageLink, getURLSearchParameters} from "utils/search/helpers";
import {replacePathParams} from "utils/replace-path-params/replace-path-params";

export const useSimilarContentPanelService = (contentId: string) => {
  const [content, setContent] = useState<ResourceElement | undefined>();
  const currentSearchParameters = getURLSearchParameters();

  const getURLToShowContent = () => {
    return content?.id ? replacePathParams(RESOURCE_PAGE_PATH, [content.id]) : "#";
  };

  const getURLToShowAll = () => {
    return getSearchPageLink({...currentSearchParameters, contentId: null});
  };

  useEffect(() => {
    fetchResourceById(contentId)
      .then(data => setContent(data.content));
  }, [contentId]);

  return {
    content,
    urlToShowContent: getURLToShowContent(),
    urlToShowAll: getURLToShowAll(),
  };
};