import {useKeycloak} from "@react-keycloak/web";
import {useAppSelector} from "app/store/hooks/use-selector";
import {getUserShortFullName} from "utils/user/name";

export const useProfile = () => {
  const {keycloak} = useKeycloak();
  const {profile} = useAppSelector(state => state.profileState);

  const login = () => {
    keycloak.login({redirectUri: window.location.href, locale: "ru"});
  };

  const logout = () => {
    keycloak.logout();
  };

  return {
    state: {
      authenticated: keycloak.authenticated,
      userName: getUserShortFullName(profile),
      avatar: profile?.avatar,
    },
    methods: {
      login,
      logout,
    }
  };
};