import {ArrowRightIcon} from "common/icons/ArrowRight";
import {AppLink} from "common/link/Link";
import {AppSpacer} from "common/spacer/Spacer";
import {AppInfoText} from "common/text/InfoText";
import {AppTitle} from "common/title/Title";
import styles from "./styles.module.scss";

type ContentMinCardListHeaderProps = {
  title: string;
  count?: number;
  link: string;
}

export const ContentMinCardListHeader = (props: ContentMinCardListHeaderProps) => {
  return (
    <AppSpacer unit="10" direction="horizontal" alignItems="flex-end">
      <AppTitle level={1}>{props.title}</AppTitle>
      {props.count && <AppInfoText className={styles.count}>Найдено ({props.count})</AppInfoText>}
      <AppLink className={styles.link} to={props.link}>
        <AppSpacer unit="8" direction="horizontal" alignItems="center">
          <span>Показать все</span>
          <ArrowRightIcon/>
        </AppSpacer>
      </AppLink>
    </AppSpacer>
  );
};