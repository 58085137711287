import {ContentCard} from "app/components/search/content-card/ContentCard";
import {ResourceElement} from "app/reducers/search/types";
import {RESOURCE_PAGE_PATH} from "app/routing/resource/constants";
import {AppSpacer} from "common/spacer/Spacer";
import {AppTitle} from "common/title/Title";
import {AppEmpty} from "common/empty/Empty";
import {getClassNames} from "utils/get-class-names/get-class-names";
import {AppLink} from "common/link/Link";
import {replacePathParams} from "utils/replace-path-params/replace-path-params";
import {HighlightProps} from "types/props";
import styles from "./styles.module.scss";

type ContentListProps = HighlightProps & {
  elements: ResourceElement[];
  title?: string;
  containerClassName?: string;
  showContentType?: boolean;
  showDescription?: boolean;
}

export const ContentCardList = (props: ContentListProps) => {
  const containerClassNames = getClassNames(styles.container, props.containerClassName);

  if (!props.elements.length) {
    return (
      <AppEmpty className={styles.empty}/>
    );
  }

  return (
    <>
      {
        props.title && (
          <AppTitle level={1} className={styles.title}>
            {props.title}
          </AppTitle>
        )
      }
      <AppSpacer unit="10" className={containerClassNames}>
        {
          props.elements.map((element: ResourceElement) => {
            return (
              <AppLink to={replacePathParams(RESOURCE_PAGE_PATH, [element.id])}
                       key={element.id}>
                <ContentCard element={element} highlight={props.highlight}
                             showContentType={props.showContentType}
                             showDescription={props.showDescription}/>
              </AppLink>
            );
          })
        }
      </AppSpacer>
    </>
  );
};
