import {getAllContentTypes} from "app/actions/search/content-types";
import {useEffect, useState} from "react";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {useKeycloak} from "@react-keycloak/web";
import {clearProfile} from "app/reducers/profile";
import {getFullProfile} from "app/actions/profile/profile";
import {KeycloakProfile} from "app/reducers/profile/types";

export const useAuth = () => {
  const {keycloak} = useKeycloak();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const resetProfile = () => {
    dispatch(clearProfile());
  };

  useEffect(() => {
    if (keycloak && keycloak.authenticated) {
      Promise.all([
        dispatch(getAllContentTypes()),
        dispatch(getFullProfile(keycloak.idTokenParsed as KeycloakProfile)),
      ]).finally(() => setLoading(false));
    } else {
      dispatch(getAllContentTypes()).finally(() => setLoading(false));
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    keycloak.onAuthRefreshError = resetProfile;
    keycloak.onAuthLogout = resetProfile;
  }, []);

  return {
    loading,
  };
};
