import {combineReducers} from "@reduxjs/toolkit";
import {contentTypesReducer} from "./content-types";
import {newAndPopularContentReducer} from "./new-and-popular";
import {searchContentReducer} from "./search";
import {profileSliceReducer} from "./profile";
import {currentElementReducer} from "./resource";
import {virtualExhibitionReducer} from "./exhibitions";
import {virtualReferenceReducer} from "./virtual-reference";
import {bypassSheetsReducer} from "./bypass-sheet";
import {statementRequestsReducer} from "./statement-request";
import {udcLbcRequestsReducer} from "./udc-lbc-requests";

export const reducer = combineReducers({
  contentTypesState: contentTypesReducer,
  searchContentState: searchContentReducer,
  newAndPopularContentState: newAndPopularContentReducer,
  profileState: profileSliceReducer,
  currentElementState: currentElementReducer,
  virtualExhibitionState: virtualExhibitionReducer,
  virtualReferenceState: virtualReferenceReducer,
  bypassSheetsState: bypassSheetsReducer,
  statementRequestsState: statementRequestsReducer,
  udcLbcRequestsState: udcLbcRequestsReducer,
});