import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {SearchResponse} from "app/reducers/search/types";
import {searchContentActions} from "app/reducers/search";
import {TSearchParameters} from "types/search-params";
import {searchContent, SearchContentParams} from "rest/search/search-content";
import {generateSearchContentParams} from "./generate-search-content-params";

type RequestOptions = {
  searchParameters: TSearchParameters;
  userId?: string;
}

export const findContent = createAsyncThunk<SearchResponse, RequestOptions>(
  "SEARCH_CONTENT", async ({searchParameters, userId}, {dispatch}) => {
    try {
      const params: SearchContentParams = generateSearchContentParams(searchParameters);
      return await searchContent(params, userId);
    } catch (e: unknown) {
      if (!axios.isCancel(e)) {
        dispatch(searchContentActions.rejectedByError());
      }
      throw new Error(e as string);
    }
  }
);