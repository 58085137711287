import axios, {CancelTokenSource} from "axios";
import {appApi} from "api-config";
import {SearchResponse} from "app/reducers/search/types";
import {TSearchParameters} from "types/search-params";
import {errorHandler} from "utils/error-handler";

export const DEFAULT_LIMIT = 10;

export type SearchContentParams = Omit<TSearchParameters, "limit" | "offset"> & {
  limit?: number | null;
  offset?: number | null;
};

const _searchContent = () => {
  let source: CancelTokenSource | undefined;

  return (searchParameters: SearchContentParams, userId?: string): Promise<SearchResponse> => {
    if (source) {
      source.cancel();
    }
    source = axios.CancelToken.source();

    const requestBody = {
      searchParameters: {...searchParameters, userId}
    };

    return appApi
      .post(
        "v2/services/educationalportal_RestSearchService/searchContent",
        requestBody,
        {
          cancelToken: source.token,
          withCredentials: true,
        },
      )
      .then(res => res.data)
      .catch(errorHandler("При обращении к серверу произошла ошибка."));
  };
};

export const searchContent = _searchContent();