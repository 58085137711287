import {ResourceElement} from "app/reducers/search/types";

export const groupByType = (data: ResourceElement[] = []): Array<ResourceElement[]> => {
  const groupedElements: Map<string, ResourceElement[]> =
    data.reduce((acc: Map<string, ResourceElement[]>, item: ResourceElement) => {
      if (!item.contentType) {
        return acc;
      }
      if (acc.has(item.contentType.id)) {
        acc.set(item.contentType.id, [...acc.get(item.contentType.id)!, item]);
      } else {
        acc.set(item.contentType.id, [item]);
      }
      return acc;
    }, new Map());

  return Array.from(groupedElements.values());
};