import {useEffect} from "react";
import {useAppSelector} from "app/store/hooks/use-selector";
import {useAppDispatch} from "app/store/hooks/use-dispatch";
import {searchContentActions} from "app/reducers/search";
import {groupByType} from "utils/group-by-type/group-by-type";
import {getURLSearchParameters} from "utils/search/helpers";
import {getHighlightParts} from "../../utils";

export const useContentAllTypesService = () => {
  const {loading, elements, contentTypeToCounts} = useAppSelector(state => state.searchContentState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(searchContentActions.clear());
    };
  }, []);

  const groupedElements = groupByType(elements);

  const getHighlight = () => {
    const {searchString, searchBy} = getURLSearchParameters();

    return {
      highlightParts: getHighlightParts(searchBy),
      pattern: searchString,
    };
  };

  return {
    loading,
    groupedElements,
    contentTypeToCounts,
    highlight: getHighlight(),
  };
};