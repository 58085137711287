import {AppImage} from "common/image/Image";
import {AppTitle} from "common/title/Title";
import {AppSpacer} from "common/spacer/Spacer";
import {useProfile} from "./hook/use-profile";
import {ExportOutlined} from "@ant-design/icons";
import {getDownloadUrl} from "utils/get-download-url/get-download-url";
import styles from "./styles.module.scss";

export const Profile = () => {
  const {state, methods} = useProfile();

  const content = (
    <>
      <AppImage className={styles.avatar} alt="avatar" src={getDownloadUrl(state.avatar)}/>
      <AppTitle level={4} className={`${styles.title} ${state.authenticated ? "" : styles.anonymous}`}>
        {state.authenticated ? state?.userName : "Войти"}
      </AppTitle>
    </>
  );

  return state.authenticated ? (
    <>
      <AppSpacer unit="8" className={styles.container}>
        {content}
      </AppSpacer>
      <AppSpacer onClick={methods.logout} className={styles.logout} justifyContent="center">
        <ExportOutlined className={styles["logout-icon"]}/>
      </AppSpacer>
    </>
  ) : (
    <AppSpacer onClick={methods.login} unit="8"
               className={styles.container}>
      {content}
    </AppSpacer>
  );
};