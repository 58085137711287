import {homePageRouteInfo} from "app/routing/home";
import {notFoundPageRouteInfo} from "app/routing/not-found";
import {resourcePageRouteInfo} from "app/routing/resource";
import {searchPageRouteInfo} from "app/routing/search";
// import {libraryPageRouteInfo} from "./library";

export const availableRoutes = [
  homePageRouteInfo,
  searchPageRouteInfo,
  resourcePageRouteInfo,
/* Commented at 2023.04.06
  libraryPageRouteInfo,
*/
  notFoundPageRouteInfo,
];
