import {useState} from "react";
import {ResourceElement} from "app/reducers/search/types";
import {useAppSelector} from "app/store/hooks/use-selector";
import {AppCard} from "common/card/Card";
import {AppCardDescription} from "common/descriptions/card-description/CardDescription";
import {AppImage} from "common/image/Image";
import {AppSpacer} from "common/spacer/Spacer";
import {AppText} from "common/text/Text";
import {AppTitle} from "common/title/Title";
import {setContentWasFound} from "rest/search/log-search-session-id";
import {USER_DATE_WITH_MONTH_FORMAT} from "utils/date/date";
import {formatDate} from "utils/date/format-date/format-date";
import {getDownloadUrl} from "utils/get-download-url/get-download-url";
import {ContentCardFullSkeleton} from "./skeleton/ContentCardFullSkeleton";
import {ReserveDescription} from "./reserve-description/ReserveDescription";
import {AppOutlineButton} from "common/button/outline/Outline";
import {parseHTML} from "utils/html-parser/html-parser";
import {AppIframe} from "common/iframe/Iframe";
import previewStyles from "../../search/styles.module.scss";
import styles from "./styles.module.scss";

type ContentCardFullProps = {
  element: ResourceElement;
  error: string;
  loading: boolean;
  openReserveModal(): void;
}

export const ContentCardFull = (props: ContentCardFullProps) => {
  const [logsSending, setLogsSending] = useState(false);
  const [modalOpening, setModalOpening] = useState(false);
  const searchLogId = useAppSelector(state => state.searchContentState.searchLogId);
  const {profile} = useAppSelector(state => state.profileState);


  // todo join toSource and openReserveModal fn
  const toSource = async () => {
    if (searchLogId) {
      setLogsSending(true);
      try {
        await setContentWasFound(searchLogId, props.element.id);
      } finally {
        window.open(props.element.link!, "__blank");
        setLogsSending(false);
      }
      return;
    }
    window.open(props.element.link!, "__blank");
  };

  const openReserveModal = async() => {
    if (searchLogId) {
      setModalOpening(true);
      try {
        await setContentWasFound(searchLogId, props.element.id);
      } finally {
        props.openReserveModal();
        setModalOpening(false);
      }
      return;
    }
    props.openReserveModal();
  };

  if (props.loading) {
    return (
      <ContentCardFullSkeleton/>
    );
  }

  if (props.error) {
    return (
      <AppCard className={styles.card}>
        <AppSpacer alignItems="center">
          <AppText size={16} type="danger">{props.error}</AppText>
        </AppSpacer>
      </AppCard>
    );
  }

  return (
    <AppCard className={styles.card}>
      <AppTitle level={1} className={styles.title}>
        {props.element.name}
      </AppTitle>
      <AppSpacer direction="horizontal" unit="40">
        <AppImage src={getDownloadUrl(props.element.image)}
                  alt={props.element.name || ""}
                  previewClassName={previewStyles[`preview-${props.element.contentType?.materialType}`]}
                  className={styles.image}/>
        <AppSpacer unit="20" className={styles.info}>
          <AppCardDescription title="Автор:">
            {props.element.authors}
          </AppCardDescription>
          {
            props.element.description && (
              <AppCardDescription title="Описание:">
                {parseHTML(props.element.description)}
              </AppCardDescription>
            )
          }
          <AppCardDescription title="Тип ресурса:">
            {props.element.contentType?.name}
          </AppCardDescription>
          <AppCardDescription title="Дата:">
            {formatDate(props.element.publishDate, USER_DATE_WITH_MONTH_FORMAT)}
          </AppCardDescription>
          <AppCardDescription title="Источник:">
            {props.element.source?.name}
          </AppCardDescription>
          {
            props.element.price && (
              <AppCardDescription title="Цена:">
                {props.element.price} ₽
              </AppCardDescription>
            )
          }
          <ReserveDescription element={props.element}/>
          <AppSpacer direction="horizontal" className={styles["button-container"]}>
            {
              props.element.availableForReserve && !!profile && (
                <AppOutlineButton disabled={!props.element.forReserve}
                                  onClick={openReserveModal}
                                  loading={modalOpening}>
                  Зарезервировать
                </AppOutlineButton>
              )
            }
            {
              props.element.link && (
                <AppOutlineButton onClick={toSource}
                                  loading={logsSending}>
                  Перейти на портал
                </AppOutlineButton>
              )
            }
          </AppSpacer>
          {
            props.element.videoUrl && (
              <AppIframe src={props.element.videoUrl}
                         className={styles.video}/>
            )
          }
        </AppSpacer>
      </AppSpacer>
    </AppCard>
  );
};
