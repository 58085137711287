import {createAsyncThunk} from "@reduxjs/toolkit";
import {notification} from "antd";
import {ResourceElement} from "app/reducers/search/types";
import {fetchResourceById} from "rest/resource/get-resource-by-id";
import {reserveContent, ReserveDTO} from "rest/resource/reserve";
import {ResourceByIdResponse} from "rest/resource/types";

type RequestOptions = {
  contentId: string;
  userId?: string;
}

export const getResourceById = createAsyncThunk("currentElement/getById", async({contentId, userId}: RequestOptions) => {
  try {
    const data: ResourceByIdResponse = await fetchResourceById(contentId, userId);
    return data;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});

export const reserve = createAsyncThunk("currentElement/reserve", async(reserveDto: ReserveDTO) => {
  try {
    const data: ResourceElement = await reserveContent(reserveDto);
    notification.success({message: "Заявка на резервирование принята"});
    return data;
  } catch (e: unknown) {
    throw new Error(e as string);
  }
});