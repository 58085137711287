import {appApi} from "api-config";
import {NewAndPopularResponse} from "app/reducers/new-and-popular/types";
import {errorHandler} from "utils/error-handler";

export const searchNewAndPopularContent = (userId?: string): Promise<NewAndPopularResponse> => {
  return appApi
    .post("v2/services/educationalportal_RestSearchService/searchNewAndPopularContent", {
      searchParameters: {
        userId
      },
    },
    {withCredentials: true})
    .then(res => res.data)
    .catch(errorHandler("При обращении к серверу произошла ошибка."));
};