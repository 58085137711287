import {appApi} from "api-config";
import {errorHandler} from "utils/error-handler";
import {ResourceByIdResponse} from "./types";

export const fetchResourceById = (contentId: string, userId?: string): Promise<ResourceByIdResponse> => {
  return appApi
    .post("v2/services/educationalportal_RestSearchService/getContentById", {
      searchParameters: {
        contentId,
        userId,
      },
    },
    {withCredentials: true}
    )
    .then(res => res.data)
    .catch(errorHandler("Не удалось загрузить данные"));
};